/**
 *  Copyright 2025 Verbleif
 *  @license MIT
**/
import { unref, onMounted, nextTick, ref, readonly, getCurrentInstance, watch, getCurrentScope, onScopeDispose, shallowRef, computed, watchEffect } from "vue";
const LogLevels = {
  silent: Number.NEGATIVE_INFINITY,
  fatal: 0,
  error: 0,
  warn: 1,
  log: 2,
  info: 3,
  success: 3,
  fail: 3,
  ready: 3,
  start: 3,
  box: 3,
  debug: 4,
  trace: 5,
  verbose: Number.POSITIVE_INFINITY
};
const LogTypes = {
  // Silent
  silent: {
    level: -1
  },
  // Level 0
  fatal: {
    level: LogLevels.fatal
  },
  error: {
    level: LogLevels.error
  },
  // Level 1
  warn: {
    level: LogLevels.warn
  },
  // Level 2
  log: {
    level: LogLevels.log
  },
  // Level 3
  info: {
    level: LogLevels.info
  },
  success: {
    level: LogLevels.success
  },
  fail: {
    level: LogLevels.fail
  },
  ready: {
    level: LogLevels.info
  },
  start: {
    level: LogLevels.info
  },
  box: {
    level: LogLevels.info
  },
  // Level 4
  debug: {
    level: LogLevels.debug
  },
  // Level 5
  trace: {
    level: LogLevels.trace
  },
  // Verbose
  verbose: {
    level: LogLevels.verbose
  }
};
function isObject$1(value) {
  return value !== null && typeof value === "object";
}
function _defu(baseObject, defaults, namespace = ".", merger) {
  if (!isObject$1(defaults)) {
    return _defu(baseObject, {}, namespace, merger);
  }
  const object = Object.assign({}, defaults);
  for (const key in baseObject) {
    if (key === "__proto__" || key === "constructor") {
      continue;
    }
    const value = baseObject[key];
    if (value === null || value === void 0) {
      continue;
    }
    if (merger && merger(object, key, value, namespace)) {
      continue;
    }
    if (Array.isArray(value) && Array.isArray(object[key])) {
      object[key] = [...value, ...object[key]];
    } else if (isObject$1(value) && isObject$1(object[key])) {
      object[key] = _defu(
        value,
        object[key],
        (namespace ? `${namespace}.` : "") + key.toString(),
        merger
      );
    } else {
      object[key] = value;
    }
  }
  return object;
}
function createDefu(merger) {
  return (...arguments_) => (
    // eslint-disable-next-line unicorn/no-array-reduce
    arguments_.reduce((p, c) => _defu(p, c, "", merger), {})
  );
}
const defu = createDefu();
function isPlainObject(obj) {
  return Object.prototype.toString.call(obj) === "[object Object]";
}
function isLogObj(arg) {
  if (!isPlainObject(arg)) {
    return false;
  }
  if (!arg.message && !arg.args) {
    return false;
  }
  if (arg.stack) {
    return false;
  }
  return true;
}
let paused = false;
const queue = [];
class Consola {
  constructor(options = {}) {
    const types = options.types || LogTypes;
    this.options = defu(
      {
        ...options,
        defaults: { ...options.defaults },
        level: _normalizeLogLevel(options.level, types),
        reporters: [...options.reporters || []]
      },
      {
        types: LogTypes,
        throttle: 1e3,
        throttleMin: 5,
        formatOptions: {
          date: true,
          colors: false,
          compact: true
        }
      }
    );
    for (const type in types) {
      const defaults = {
        type,
        ...this.options.defaults,
        ...types[type]
      };
      this[type] = this._wrapLogFn(defaults);
      this[type].raw = this._wrapLogFn(
        defaults,
        true
      );
    }
    if (this.options.mockFn) {
      this.mockTypes();
    }
    this._lastLog = {};
  }
  get level() {
    return this.options.level;
  }
  set level(level2) {
    this.options.level = _normalizeLogLevel(
      level2,
      this.options.types,
      this.options.level
    );
  }
  prompt(message, opts) {
    if (!this.options.prompt) {
      throw new Error("prompt is not supported!");
    }
    return this.options.prompt(message, opts);
  }
  create(options) {
    const instance = new Consola({
      ...this.options,
      ...options
    });
    if (this._mockFn) {
      instance.mockTypes(this._mockFn);
    }
    return instance;
  }
  withDefaults(defaults) {
    return this.create({
      ...this.options,
      defaults: {
        ...this.options.defaults,
        ...defaults
      }
    });
  }
  withTag(tag) {
    return this.withDefaults({
      tag: this.options.defaults.tag ? this.options.defaults.tag + ":" + tag : tag
    });
  }
  addReporter(reporter) {
    this.options.reporters.push(reporter);
    return this;
  }
  removeReporter(reporter) {
    if (reporter) {
      const i = this.options.reporters.indexOf(reporter);
      if (i >= 0) {
        return this.options.reporters.splice(i, 1);
      }
    } else {
      this.options.reporters.splice(0);
    }
    return this;
  }
  setReporters(reporters) {
    this.options.reporters = Array.isArray(reporters) ? reporters : [reporters];
    return this;
  }
  wrapAll() {
    this.wrapConsole();
    this.wrapStd();
  }
  restoreAll() {
    this.restoreConsole();
    this.restoreStd();
  }
  wrapConsole() {
    for (const type in this.options.types) {
      if (!console["__" + type]) {
        console["__" + type] = console[type];
      }
      console[type] = this[type].raw;
    }
  }
  restoreConsole() {
    for (const type in this.options.types) {
      if (console["__" + type]) {
        console[type] = console["__" + type];
        delete console["__" + type];
      }
    }
  }
  wrapStd() {
    this._wrapStream(this.options.stdout, "log");
    this._wrapStream(this.options.stderr, "log");
  }
  _wrapStream(stream, type) {
    if (!stream) {
      return;
    }
    if (!stream.__write) {
      stream.__write = stream.write;
    }
    stream.write = (data) => {
      this[type].raw(String(data).trim());
    };
  }
  restoreStd() {
    this._restoreStream(this.options.stdout);
    this._restoreStream(this.options.stderr);
  }
  _restoreStream(stream) {
    if (!stream) {
      return;
    }
    if (stream.__write) {
      stream.write = stream.__write;
      delete stream.__write;
    }
  }
  pauseLogs() {
    paused = true;
  }
  resumeLogs() {
    paused = false;
    const _queue = queue.splice(0);
    for (const item of _queue) {
      item[0]._logFn(item[1], item[2]);
    }
  }
  mockTypes(mockFn) {
    const _mockFn = mockFn || this.options.mockFn;
    this._mockFn = _mockFn;
    if (typeof _mockFn !== "function") {
      return;
    }
    for (const type in this.options.types) {
      this[type] = _mockFn(type, this.options.types[type]) || this[type];
      this[type].raw = this[type];
    }
  }
  _wrapLogFn(defaults, isRaw) {
    return (...args) => {
      if (paused) {
        queue.push([this, defaults, args, isRaw]);
        return;
      }
      return this._logFn(defaults, args, isRaw);
    };
  }
  _logFn(defaults, args, isRaw) {
    if ((defaults.level || 0) > this.level) {
      return false;
    }
    const logObj = {
      date: /* @__PURE__ */ new Date(),
      args: [],
      ...defaults,
      level: _normalizeLogLevel(defaults.level, this.options.types)
    };
    if (!isRaw && args.length === 1 && isLogObj(args[0])) {
      Object.assign(logObj, args[0]);
    } else {
      logObj.args = [...args];
    }
    if (logObj.message) {
      logObj.args.unshift(logObj.message);
      delete logObj.message;
    }
    if (logObj.additional) {
      if (!Array.isArray(logObj.additional)) {
        logObj.additional = logObj.additional.split("\n");
      }
      logObj.args.push("\n" + logObj.additional.join("\n"));
      delete logObj.additional;
    }
    logObj.type = typeof logObj.type === "string" ? logObj.type.toLowerCase() : "log";
    logObj.tag = typeof logObj.tag === "string" ? logObj.tag : "";
    const resolveLog = (newLog = false) => {
      const repeated = (this._lastLog.count || 0) - this.options.throttleMin;
      if (this._lastLog.object && repeated > 0) {
        const args2 = [...this._lastLog.object.args];
        if (repeated > 1) {
          args2.push(`(repeated ${repeated} times)`);
        }
        this._log({ ...this._lastLog.object, args: args2 });
        this._lastLog.count = 1;
      }
      if (newLog) {
        this._lastLog.object = logObj;
        this._log(logObj);
      }
    };
    clearTimeout(this._lastLog.timeout);
    const diffTime = this._lastLog.time && logObj.date ? logObj.date.getTime() - this._lastLog.time.getTime() : 0;
    this._lastLog.time = logObj.date;
    if (diffTime < this.options.throttle) {
      try {
        const serializedLog = JSON.stringify([
          logObj.type,
          logObj.tag,
          logObj.args
        ]);
        const isSameLog = this._lastLog.serialized === serializedLog;
        this._lastLog.serialized = serializedLog;
        if (isSameLog) {
          this._lastLog.count = (this._lastLog.count || 0) + 1;
          if (this._lastLog.count > this.options.throttleMin) {
            this._lastLog.timeout = setTimeout(
              resolveLog,
              this.options.throttle
            );
            return;
          }
        }
      } catch {
      }
    }
    resolveLog(true);
  }
  _log(logObj) {
    for (const reporter of this.options.reporters) {
      reporter.log(logObj, {
        options: this.options
      });
    }
  }
}
function _normalizeLogLevel(input, types = {}, defaultLevel = 3) {
  if (input === void 0) {
    return defaultLevel;
  }
  if (typeof input === "number") {
    return input;
  }
  if (types[input] && types[input].level !== void 0) {
    return types[input].level;
  }
  return defaultLevel;
}
Consola.prototype.add = Consola.prototype.addReporter;
Consola.prototype.remove = Consola.prototype.removeReporter;
Consola.prototype.clear = Consola.prototype.removeReporter;
Consola.prototype.withScope = Consola.prototype.withTag;
Consola.prototype.mock = Consola.prototype.mockTypes;
Consola.prototype.pause = Consola.prototype.pauseLogs;
Consola.prototype.resume = Consola.prototype.resumeLogs;
function createConsola$1(options = {}) {
  return new Consola(options);
}
class BrowserReporter {
  constructor(options) {
    this.options = { ...options };
    this.defaultColor = "#7f8c8d";
    this.levelColorMap = {
      0: "#c0392b",
      // Red
      1: "#f39c12",
      // Yellow
      3: "#00BCD4"
      // Cyan
    };
    this.typeColorMap = {
      success: "#2ecc71"
      // Green
    };
  }
  _getLogFn(level2) {
    if (level2 < 1) {
      return console.__error || console.error;
    }
    if (level2 === 1) {
      return console.__warn || console.warn;
    }
    return console.__log || console.log;
  }
  log(logObj) {
    const consoleLogFn = this._getLogFn(logObj.level);
    const type = logObj.type === "log" ? "" : logObj.type;
    const tag = logObj.tag || "";
    const color = this.typeColorMap[logObj.type] || this.levelColorMap[logObj.level] || this.defaultColor;
    const style = `
      background: ${color};
      border-radius: 0.5em;
      color: white;
      font-weight: bold;
      padding: 2px 0.5em;
    `;
    const badge = `%c${[tag, type].filter(Boolean).join(":")}`;
    if (typeof logObj.args[0] === "string") {
      consoleLogFn(
        `${badge}%c ${logObj.args[0]}`,
        style,
        // Empty string as style resets to default console style
        "",
        ...logObj.args.slice(1)
      );
    } else {
      consoleLogFn(badge, style, ...logObj.args);
    }
  }
}
function createConsola(options = {}) {
  const consola2 = createConsola$1({
    reporters: options.reporters || [new BrowserReporter({})],
    prompt(message, options2 = {}) {
      if (options2.type === "confirm") {
        return Promise.resolve(confirm(message));
      }
      return Promise.resolve(prompt(message));
    },
    ...options
  });
  return consola2;
}
createConsola();
const level = {}.VITE_LOG_LEVEL || LogLevels.info;
const consola = createConsola({
  level: Number.parseInt(level)
});
var AuthPath = /* @__PURE__ */ ((AuthPath2) => {
  AuthPath2["JSON_LOGIN_V1"] = "/api/v1/token/login";
  AuthPath2["JSON_LOGIN_V2"] = "/api/auth/login";
  AuthPath2["JSON_REFRESH_V1"] = "/api/v1/token/refresh";
  AuthPath2["JSON_REFRESH_V2"] = "/api/auth/refresh";
  return AuthPath2;
})(AuthPath || {});
function createAuthenticatePlugin(config) {
  const currentPromise = {
    [
      "/api/v1/token/login"
      /* JSON_LOGIN_V1 */
    ]: null,
    [
      "/api/auth/login"
      /* JSON_LOGIN_V2 */
    ]: null,
    [
      "/api/v1/token/refresh"
      /* JSON_REFRESH_V1 */
    ]: null,
    [
      "/api/auth/refresh"
      /* JSON_REFRESH_V2 */
    ]: null
  };
  function clearCurrentPromise() {
    currentPromise[config.path] = null;
  }
  async function authenticate(input) {
    let promise = currentPromise[config.path];
    if (!promise) {
      promise = config.axios.post(config.path, input).then(async (r) => {
        if (config.onAuthenticateSuccess) {
          await config.onAuthenticateSuccess(r.data);
        }
        return r.data;
      }).catch(async (e) => {
        if (config.onAuthenticateFailed) {
          await config.onAuthenticateFailed(e);
        }
        throw e;
      }).finally(clearCurrentPromise);
      currentPromise[config.path] = promise;
    }
    await promise;
    return promise;
  }
  return { authenticate };
}
var LoginTypeEnum = /* @__PURE__ */ ((LoginTypeEnum2) => {
  LoginTypeEnum2["CMS"] = "verbleif_cms";
  LoginTypeEnum2["WEB"] = "verbleif_web";
  return LoginTypeEnum2;
})(LoginTypeEnum || {});
function tryOnScopeDispose(fn) {
  if (getCurrentScope()) {
    onScopeDispose(fn);
    return true;
  }
  return false;
}
function toValue(r) {
  return typeof r === "function" ? r() : unref(r);
}
const isClient = typeof window !== "undefined" && typeof document !== "undefined";
typeof WorkerGlobalScope !== "undefined" && globalThis instanceof WorkerGlobalScope;
const toString = Object.prototype.toString;
const isObject = (val) => toString.call(val) === "[object Object]";
const noop = () => {
};
function createFilterWrapper(filter, fn) {
  function wrapper(...args) {
    return new Promise((resolve, reject) => {
      Promise.resolve(filter(() => fn.apply(this, args), { fn, thisArg: this, args })).then(resolve).catch(reject);
    });
  }
  return wrapper;
}
const bypassFilter = (invoke) => {
  return invoke();
};
function pausableFilter(extendFilter = bypassFilter) {
  const isActive = ref(true);
  function pause() {
    isActive.value = false;
  }
  function resume() {
    isActive.value = true;
  }
  const eventFilter = (...args) => {
    if (isActive.value)
      extendFilter(...args);
  };
  return { isActive: readonly(isActive), pause, resume, eventFilter };
}
function getLifeCycleTarget(target) {
  return target || getCurrentInstance();
}
function watchWithFilter(source, cb, options = {}) {
  const {
    eventFilter = bypassFilter,
    ...watchOptions
  } = options;
  return watch(
    source,
    createFilterWrapper(
      eventFilter,
      cb
    ),
    watchOptions
  );
}
function watchPausable(source, cb, options = {}) {
  const {
    eventFilter: filter,
    ...watchOptions
  } = options;
  const { eventFilter, pause, resume, isActive } = pausableFilter(filter);
  const stop = watchWithFilter(
    source,
    cb,
    {
      ...watchOptions,
      eventFilter
    }
  );
  return { stop, pause, resume, isActive };
}
function tryOnMounted(fn, sync = true, target) {
  const instance = getLifeCycleTarget();
  if (instance)
    onMounted(fn, target);
  else if (sync)
    fn();
  else
    nextTick(fn);
}
function unrefElement(elRef) {
  var _a;
  const plain = toValue(elRef);
  return (_a = plain == null ? void 0 : plain.$el) != null ? _a : plain;
}
const defaultWindow = isClient ? window : void 0;
function useEventListener(...args) {
  let target;
  let events;
  let listeners;
  let options;
  if (typeof args[0] === "string" || Array.isArray(args[0])) {
    [events, listeners, options] = args;
    target = defaultWindow;
  } else {
    [target, events, listeners, options] = args;
  }
  if (!target)
    return noop;
  if (!Array.isArray(events))
    events = [events];
  if (!Array.isArray(listeners))
    listeners = [listeners];
  const cleanups = [];
  const cleanup = () => {
    cleanups.forEach((fn) => fn());
    cleanups.length = 0;
  };
  const register = (el, event, listener, options2) => {
    el.addEventListener(event, listener, options2);
    return () => el.removeEventListener(event, listener, options2);
  };
  const stopWatch = watch(
    () => [unrefElement(target), toValue(options)],
    ([el, options2]) => {
      cleanup();
      if (!el)
        return;
      const optionsClone = isObject(options2) ? { ...options2 } : options2;
      cleanups.push(
        ...events.flatMap((event) => {
          return listeners.map((listener) => register(el, event, listener, optionsClone));
        })
      );
    },
    { immediate: true, flush: "post" }
  );
  const stop = () => {
    stopWatch();
    cleanup();
  };
  tryOnScopeDispose(stop);
  return stop;
}
const _global = typeof globalThis !== "undefined" ? globalThis : typeof window !== "undefined" ? window : typeof global !== "undefined" ? global : typeof self !== "undefined" ? self : {};
const globalKey = "__vueuse_ssr_handlers__";
const handlers = /* @__PURE__ */ getHandlers();
function getHandlers() {
  if (!(globalKey in _global))
    _global[globalKey] = _global[globalKey] || {};
  return _global[globalKey];
}
function getSSRHandler(key, fallback) {
  return handlers[key] || fallback;
}
function guessSerializerType(rawInit) {
  return rawInit == null ? "any" : rawInit instanceof Set ? "set" : rawInit instanceof Map ? "map" : rawInit instanceof Date ? "date" : typeof rawInit === "boolean" ? "boolean" : typeof rawInit === "string" ? "string" : typeof rawInit === "object" ? "object" : !Number.isNaN(rawInit) ? "number" : "any";
}
const StorageSerializers = {
  boolean: {
    read: (v) => v === "true",
    write: (v) => String(v)
  },
  object: {
    read: (v) => JSON.parse(v),
    write: (v) => JSON.stringify(v)
  },
  number: {
    read: (v) => Number.parseFloat(v),
    write: (v) => String(v)
  },
  any: {
    read: (v) => v,
    write: (v) => String(v)
  },
  string: {
    read: (v) => v,
    write: (v) => String(v)
  },
  map: {
    read: (v) => new Map(JSON.parse(v)),
    write: (v) => JSON.stringify(Array.from(v.entries()))
  },
  set: {
    read: (v) => new Set(JSON.parse(v)),
    write: (v) => JSON.stringify(Array.from(v))
  },
  date: {
    read: (v) => new Date(v),
    write: (v) => v.toISOString()
  }
};
const customStorageEventName = "vueuse-storage";
function useStorage(key, defaults, storage, options = {}) {
  var _a;
  const {
    flush = "pre",
    deep = true,
    listenToStorageChanges = true,
    writeDefaults = true,
    mergeDefaults = false,
    shallow,
    window: window2 = defaultWindow,
    eventFilter,
    onError = (e) => {
      console.error(e);
    },
    initOnMounted
  } = options;
  const data = (shallow ? shallowRef : ref)(typeof defaults === "function" ? defaults() : defaults);
  if (!storage) {
    try {
      storage = getSSRHandler("getDefaultStorage", () => {
        var _a2;
        return (_a2 = defaultWindow) == null ? void 0 : _a2.localStorage;
      })();
    } catch (e) {
      onError(e);
    }
  }
  if (!storage)
    return data;
  const rawInit = toValue(defaults);
  const type = guessSerializerType(rawInit);
  const serializer = (_a = options.serializer) != null ? _a : StorageSerializers[type];
  const { pause: pauseWatch, resume: resumeWatch } = watchPausable(
    data,
    () => write(data.value),
    { flush, deep, eventFilter }
  );
  if (window2 && listenToStorageChanges) {
    tryOnMounted(() => {
      useEventListener(window2, "storage", update);
      useEventListener(window2, customStorageEventName, updateFromCustomEvent);
      if (initOnMounted)
        update();
    });
  }
  if (!initOnMounted)
    update();
  function dispatchWriteEvent(oldValue, newValue) {
    if (window2) {
      window2.dispatchEvent(new CustomEvent(customStorageEventName, {
        detail: {
          key,
          oldValue,
          newValue,
          storageArea: storage
        }
      }));
    }
  }
  function write(v) {
    try {
      const oldValue = storage.getItem(key);
      if (v == null) {
        dispatchWriteEvent(oldValue, null);
        storage.removeItem(key);
      } else {
        const serialized = serializer.write(v);
        if (oldValue !== serialized) {
          storage.setItem(key, serialized);
          dispatchWriteEvent(oldValue, serialized);
        }
      }
    } catch (e) {
      onError(e);
    }
  }
  function read(event) {
    const rawValue = event ? event.newValue : storage.getItem(key);
    if (rawValue == null) {
      if (writeDefaults && rawInit != null)
        storage.setItem(key, serializer.write(rawInit));
      return rawInit;
    } else if (!event && mergeDefaults) {
      const value = serializer.read(rawValue);
      if (typeof mergeDefaults === "function")
        return mergeDefaults(value, rawInit);
      else if (type === "object" && !Array.isArray(value))
        return { ...rawInit, ...value };
      return value;
    } else if (typeof rawValue !== "string") {
      return rawValue;
    } else {
      return serializer.read(rawValue);
    }
  }
  function update(event) {
    if (event && event.storageArea !== storage)
      return;
    if (event && event.key == null) {
      data.value = rawInit;
      return;
    }
    if (event && event.key !== key)
      return;
    pauseWatch();
    try {
      if ((event == null ? void 0 : event.newValue) !== serializer.write(data.value))
        data.value = read(event);
    } catch (e) {
      onError(e);
    } finally {
      if (event)
        nextTick(resumeWatch);
      else
        resumeWatch();
    }
  }
  function updateFromCustomEvent(event) {
    update(event.detail);
  }
  return data;
}
function useLocalStorage(key, initialValue, options = {}) {
  const { window: window2 = defaultWindow } = options;
  return useStorage(key, initialValue, window2 == null ? void 0 : window2.localStorage, options);
}
function useSessionStorage(key, initialValue, options = {}) {
  const { window: window2 = defaultWindow } = options;
  return useStorage(key, initialValue, window2 == null ? void 0 : window2.sessionStorage, options);
}
function createAuthStore() {
  const token = useSessionStorage("token", null);
  const refreshTokenRemembered = useLocalStorage("refreshTokenRemembered", null);
  const refreshToken = useSessionStorage("refreshToken", null);
  const client = ref(null);
  const user = ref(null);
  const isAuthenticated = computed(() => {
    return user.value !== null;
  });
  const isRemembered = computed(() => {
    return refreshTokenRemembered.value !== null;
  });
  const hasToken = computed(() => {
    return token.value !== null;
  });
  const getRefreshToken = computed(() => {
    return refreshToken.value ? refreshToken.value : refreshTokenRemembered.value;
  });
  const getRequestHeaders = computed(() => {
    return {
      Authorization: `Bearer ${token.value}`
    };
  });
  const tokenPayload = computed(
    () => {
      if (token.value === null) {
        return null;
      }
      const base64Url = token.value.split(".")[1];
      const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
      const jsonPayload = decodeURIComponent(
        atob(base64).split("").map((c) => {
          return `%${`00${c.charCodeAt(0).toString(16)}`.slice(-2)}`;
        }).join("")
      );
      const parsed = JSON.parse(jsonPayload);
      if (!parsed || typeof parsed !== "object") {
        return null;
      }
      return parsed;
    }
  );
  function setToken(inputToken) {
    token.value = inputToken;
  }
  function setUser(userInput) {
    user.value = userInput;
  }
  function setClient(clientInput) {
    client.value = clientInput;
  }
  function patchUser(us) {
    if (!user.value) {
      return;
    }
    Object.assign(user.value, us);
  }
  function setRefreshToken(refresh, remembered = isRemembered.value) {
    if (!refresh) {
      refreshToken.value = null;
      refreshTokenRemembered.value = null;
      return;
    }
    refreshToken.value = refresh;
    if (remembered) {
      refreshTokenRemembered.value = refreshToken.value;
    }
  }
  function reset() {
    setToken(null);
    setRefreshToken(null);
    client.value = null;
    user.value = null;
  }
  return {
    getRequestHeaders,
    client,
    refreshTokenRemembered,
    user,
    tokenPayload,
    getRefreshToken,
    token,
    isRemembered,
    refreshToken,
    hasToken,
    isAuthenticated,
    patchUser,
    reset,
    setClient,
    setUser,
    setRefreshToken,
    setToken
  };
}
let backwardsCompatibleInstance$3 = null;
function useAuthStore() {
  if (backwardsCompatibleInstance$3 === null) {
    backwardsCompatibleInstance$3 = createAuthStore();
  }
  return backwardsCompatibleInstance$3;
}
function createLocationStore() {
  const locations = ref(null);
  const selectedLocation = useSessionStorage("selectedLocation", null);
  const isAnyImportRunning = computed(() => {
    if (!locations.value) {
      return false;
    }
    return locations.value.filter((l) => l.importRunning).length > 0;
  });
  const hasMultipleLocations = computed(() => {
    if (!locations.value) {
      return false;
    }
    return locations.value.length > 1;
  });
  const getLocationsIriNameMap = computed(() => {
    if (!locations.value) {
      return {};
    }
    return locations.value.reduce((a, v) => {
      return { ...a, [v["@id"]]: v.name };
    }, {});
  });
  const getLocationsIriIdMap = computed(() => {
    if (!locations.value) {
      return {};
    }
    return locations.value.reduce((a, v) => {
      return { ...a, [v["@id"]]: v.id };
    }, {});
  });
  const getLocationsIriMap = computed(() => {
    if (!locations.value) {
      return {};
    }
    return locations.value.reduce((a, v) => {
      return { ...a, [v["@id"]]: v };
    }, {});
  });
  const selectedLocationObject = computed(() => {
    var _a;
    if (!selectedLocation.value) {
      return null;
    }
    return (_a = getLocationsIriMap.value) == null ? void 0 : _a[selectedLocation.value];
  });
  const getSelectedLocationId = computed(() => {
    var _a;
    return ((_a = selectedLocationObject.value) == null ? void 0 : _a.id) || null;
  });
  function setLocations(newLocations) {
    var _a;
    locations.value = newLocations;
    const newLocation = newLocations == null ? void 0 : newLocations[0];
    if (selectedLocation.value && !((_a = getLocationsIriIdMap.value) == null ? void 0 : _a[selectedLocation.value])) {
      setSelectedLocation(newLocation["@id"]);
    }
    if (newLocations.length === 1 && selectedLocation.value !== newLocation["@id"]) {
      setSelectedLocation(newLocation["@id"]);
    }
  }
  function setImportRunningForLocationIri(iri, isRunning) {
    if (!locations.value) {
      console.error("Cant set setImportRunningForLocationId since there are no locations");
      return;
    }
    const index = locations.value.findIndex((l) => l["@id"] === iri);
    if (index === -1) {
      return;
    }
    locations.value[index].importRunning = isRunning;
  }
  function setSelectedLocation(val) {
    selectedLocation.value = val;
  }
  function reset() {
    setSelectedLocation(null);
    locations.value = null;
  }
  const hasMoreThanOneLocation = computed(() => {
    return locations.value !== null && locations.value.length > 1;
  });
  return {
    locations,
    selectedLocation,
    getLocationsIriIdMap,
    getSelectedLocationId,
    hasMoreThanOneLocation,
    selectedLocationObject,
    getLocationsIriNameMap,
    hasMultipleLocations,
    isAnyImportRunning,
    reset,
    setSelectedLocation,
    setImportRunningForLocationIri,
    setLocations
  };
}
let backwardsCompatibleInstance$2 = null;
function useLocationStore() {
  if (backwardsCompatibleInstance$2 === null) {
    backwardsCompatibleInstance$2 = createLocationStore();
  }
  return backwardsCompatibleInstance$2;
}
function createConfigStore() {
  const initialConfig = ref(null);
  const overrideConfig = useSessionStorage(
    "overrideConfig",
    null,
    {
      serializer: {
        read: (v) => v ? JSON.parse(v) : null,
        write: (v) => JSON.stringify(v)
      }
    }
  );
  function setInitialConfig(newConfig) {
    initialConfig.value = newConfig;
  }
  function setOverrideConfig(newConfig) {
    overrideConfig.value = newConfig;
  }
  function reset() {
    overrideConfig.value = null;
  }
  const config = computed(() => {
    if (overrideConfig.value) {
      return overrideConfig.value;
    }
    return initialConfig.value;
  });
  return {
    config,
    setOverrideConfig,
    setInitialConfig,
    reset
  };
}
let backwardsCompatibleInstance$1 = null;
function useConfigStore() {
  if (backwardsCompatibleInstance$1 === null) {
    backwardsCompatibleInstance$1 = createConfigStore();
  }
  return backwardsCompatibleInstance$1;
}
function createImpersonationStore() {
  const impersonationUserIdentifier = useSessionStorage("impersonationUserIdentifier", null);
  const impersonationClientIdentifier = useSessionStorage("impersonationClientIdentifier", null);
  const isImpersonating = computed(() => {
    return impersonationUserIdentifier.value !== null && impersonationClientIdentifier.value !== null;
  });
  const getRequestHeaders = computed(() => {
    const headers = {};
    if (impersonationUserIdentifier.value !== null && impersonationClientIdentifier.value !== null) {
      headers["X-Switch-Type"] = "verbleif_web";
      headers["X-Switch-Username"] = impersonationUserIdentifier.value;
      headers["X-Switch-Client-Id"] = impersonationClientIdentifier.value;
    }
    return headers;
  });
  function setImpersonationUserIdentifier(id) {
    impersonationUserIdentifier.value = id;
  }
  function setImpersonationClientIdentifier(id) {
    impersonationClientIdentifier.value = id;
  }
  function reset() {
    impersonationUserIdentifier.value = null;
    impersonationClientIdentifier.value = null;
  }
  return {
    isImpersonating,
    impersonationUserIdentifier,
    impersonationClientIdentifier,
    getRequestHeaders,
    setImpersonationUserIdentifier,
    setImpersonationClientIdentifier,
    reset
  };
}
let backwardsCompatibleInstance = null;
function useImpersonationStore() {
  if (backwardsCompatibleInstance === null) {
    backwardsCompatibleInstance = createImpersonationStore();
  }
  return backwardsCompatibleInstance;
}
var Feature = /* @__PURE__ */ ((Feature2) => {
  Feature2["BOOKING_EXPERTS"] = "booking-experts";
  Feature2["APP"] = "app";
  Feature2["WEB_FIREBASE_NOTIFICATIONS"] = "firebase";
  Feature2["CUSTOM_REPORT_LISTS"] = "custom-report-lists";
  Feature2["CSV_IMPORT"] = "csv";
  Feature2["CSV_IMPORT_ADVANCED"] = "csv-import-advanced";
  Feature2["SUBSCRIPTION_MODULE"] = "subscription";
  Feature2["LOCATION_MODULE"] = "locations";
  Feature2["ROLES_MODULE"] = "roles";
  Feature2["SORT_TYPES"] = "sort-types";
  Feature2["TASKS_DONE_REPORTING"] = "task-status-done";
  Feature2["CHANGE_OVER_DAY_MODULE"] = "change-over-day";
  Feature2["TASK_LISTS"] = "task-lists";
  Feature2["TASK_CREATE_BLOCKING_DEFAULT_TRUE"] = "task-create-blocking-default-true";
  Feature2["TASKS_DATE_FILTER"] = "tasks-date-filter";
  Feature2["MANUAL_THIRD_PARTY_FIELDS"] = "manual-third-party-fields";
  Feature2["HIDE_REPORTS_DEPARTMENTS"] = "hide-reports-departments";
  Feature2["HIDE_INSIDE"] = "hide-inside";
  Feature2["HIDE_GROUP"] = "hide-group";
  Feature2["HIDE_QUANTITY"] = "hide-quantity";
  Feature2["NOTIFY_DP_MANAGERS"] = "notifications-to-department-managers";
  Feature2["PUBLIC_REPORTS"] = "public-reports";
  Feature2["TASK_STATUS_CHANGE_NOTIFICATION"] = "task-status-change-notification";
  Feature2["TASK_CREATED_NOTIFICATION_DP_MANAGERS"] = "task-created-notifications-to-department-managers";
  Feature2["TASK_COMMENT_NOTIFICATIONS"] = "task-comments-notification";
  Feature2["APP_FOCUSED_ON_LOOP_ROUTE"] = "focused_tasks_route";
  Feature2["STRATECH_CONNECTION"] = "stratech";
  Feature2["HIDE_CREATE_TASK"] = "hide-task-create";
  Feature2["HIDE_PRIORITY"] = "hide-priority";
  Feature2["DISABLE_REPORT_NOTIFICATIONS"] = "disable-report-notifications";
  Feature2["APP_FOCUSED_ON_MY_TASKS_TILL_TODAY"] = "focused_tasks_until_today";
  Feature2["ASYNC_BEX_AUTOMATIONS"] = "use-bex-automations";
  Feature2["PUBLIC_NOTIFICATIONS_GREAT_STAY_APP"] = "great-stay-app";
  Feature2["PUBLIC_NOTIFICATIONS_WEMA"] = "wema-mobile";
  Feature2["HIDE_REPORTS"] = "hide-reports";
  Feature2["HAS_ADVANCED_TASKS_EXPORT"] = "has-advanced-tasks-export";
  Feature2["ANY_CAN_ASSIGN_TO_FRONTDESK"] = "any-can-assign-to-frontdesk";
  Feature2["ONLY_DEPARTMENT_MANAGER_CAN_ASSIGN_TO_FRONTDESK"] = "only-department-manager-can-assign-to-frontdesk";
  Feature2["ALLOW_ANY_TASK_STATUS_COMPLETED"] = "allow-any-task-status-completed";
  return Feature2;
})(Feature || {});
var UserRole = /* @__PURE__ */ ((UserRole2) => {
  UserRole2[UserRole2["ROLE_ADMIN"] = 0] = "ROLE_ADMIN";
  UserRole2[UserRole2["ROLE_MANAGER"] = 1] = "ROLE_MANAGER";
  UserRole2[UserRole2["ROLE_PARK_MANAGER"] = 2] = "ROLE_PARK_MANAGER";
  UserRole2[UserRole2["ROLE_FRONT_DESK"] = 3] = "ROLE_FRONT_DESK";
  UserRole2[UserRole2["ROLE_DEPARTMENT_MANAGER"] = 4] = "ROLE_DEPARTMENT_MANAGER";
  UserRole2[UserRole2["ROLE_USER"] = 5] = "ROLE_USER";
  return UserRole2;
})(UserRole || {});
function createRightsStore(authStore) {
  const effectiveFeatures = ref([]);
  const roles = [
    0,
    1,
    3,
    4,
    5
    /* ROLE_USER */
  ];
  watch(
    authStore.user,
    () => {
      if (hasFeature(
        "locations"
        /* LOCATION_MODULE */
      ) && !roles.includes(
        2
        /* ROLE_PARK_MANAGER */
      )) {
        roles.push(
          2
          /* ROLE_PARK_MANAGER */
        );
      }
      roles.sort();
    },
    { immediate: true }
  );
  function hasRole(roleName, subject = authStore.user.value) {
    var _a;
    if (!subject) {
      return false;
    }
    const highestRoleString = (_a = subject.roles) == null ? void 0 : _a[0];
    if (typeof highestRoleString !== "string") {
      throw new TypeError(`User with id ${subject.id} has no roles.`);
    }
    if (!(highestRoleString in UserRole)) {
      throw new Error(`Role ${highestRoleString} does not exist inside enum.`);
    }
    const highestUserRole = UserRole[highestRoleString];
    return roleName >= highestUserRole;
  }
  function hasRoleStrict(roleName, subject = authStore.user.value) {
    if (!subject) {
      return false;
    }
    return subject.roles.includes(UserRole[roleName]);
  }
  function getRoles(excludeOwnRole = true, subject = authStore.user.value) {
    var _a;
    if (!subject) {
      return [];
    }
    const highestRoleString = (_a = subject.roles) == null ? void 0 : _a[0];
    if (typeof highestRoleString !== "string") {
      throw new TypeError(`User with id ${subject.id} has no roles.`);
    }
    if (!(highestRoleString in UserRole)) {
      throw new Error(`Role ${highestRoleString} does not exist inside enum.`);
    }
    const highestUserRole = UserRole[highestRoleString];
    if (excludeOwnRole) {
      return roles.filter((i) => i !== highestUserRole);
    }
    return roles;
  }
  function reset() {
    effectiveFeatures.value = [];
  }
  function hasFeature(inputFeature, inputEffectiveFeatures = null) {
    let didMatch = false;
    if (!inputEffectiveFeatures) {
      inputEffectiveFeatures = effectiveFeatures.value;
    }
    for (const feature of inputEffectiveFeatures) {
      if (typeof feature === "string") {
        if (feature === inputFeature) {
          didMatch = true;
          break;
        }
        continue;
      }
      if (feature.alias === inputFeature.toString()) {
        didMatch = true;
        break;
      }
    }
    return didMatch;
  }
  function setEffectiveFeatures(features) {
    effectiveFeatures.value = features;
  }
  return {
    hasRoleStrict,
    getRoles,
    hasRole,
    hasFeature,
    setEffectiveFeatures,
    reset
  };
}
var ThemeSetting = /* @__PURE__ */ ((ThemeSetting2) => {
  ThemeSetting2["DARK"] = "dark";
  ThemeSetting2["LIGHT"] = "light";
  ThemeSetting2["AUTO"] = "auto";
  return ThemeSetting2;
})(ThemeSetting || {});
var Theme = /* @__PURE__ */ ((Theme2) => {
  Theme2["DARK"] = "dark";
  Theme2["LIGHT"] = "light";
  return Theme2;
})(Theme || {});
const LOCAL_STORAGE_KEY = "themeSetting";
const DEFAULT_THEME = "auto";
function createTheme() {
  const themeSetting = ref(loadTheme());
  const systemPreferredTheme = ref(getSystemPreferredTheme());
  const theme2 = computed(() => {
    if (themeSetting.value === "auto") {
      return systemPreferredTheme.value;
    }
    return themeSetting.value;
  });
  watchEffect(() => {
    localStorage.setItem(LOCAL_STORAGE_KEY, themeSetting.value);
    document.body.setAttribute("data-theme", theme2.value);
    if (themeSetting.value === "auto") {
      enableWatchSystemTheme();
      return;
    }
    disableWatchSystemTheme();
  });
  function loadTheme() {
    let storedTheme = localStorage.getItem(LOCAL_STORAGE_KEY);
    if (!storedTheme || storedTheme && !["auto", "dark", "light"].includes(storedTheme)) {
      localStorage.setItem(LOCAL_STORAGE_KEY, DEFAULT_THEME);
      storedTheme = DEFAULT_THEME;
    }
    return storedTheme;
  }
  const isAuto = computed(() => {
    return themeSetting.value === "auto";
  });
  function getSystemPreferredTheme() {
    return window.matchMedia("(prefers-color-scheme: dark)").matches ? "dark" : "light";
  }
  function handleMatchMediaChange(e) {
    systemPreferredTheme.value = e.matches ? "dark" : "light";
  }
  function enableWatchSystemTheme() {
    window.matchMedia("(prefers-color-scheme: dark)").addEventListener("change", handleMatchMediaChange);
  }
  function disableWatchSystemTheme() {
    window.matchMedia("(prefers-color-scheme: dark)").removeEventListener("change", handleMatchMediaChange);
  }
  return {
    theme: theme2,
    isAuto,
    themeSetting
  };
}
const theme = createTheme();
function useTheme() {
  return theme;
}
export {
  AuthPath,
  Feature,
  LoginTypeEnum,
  Theme,
  ThemeSetting,
  UserRole,
  consola,
  createAuthenticatePlugin,
  createRightsStore,
  theme,
  useAuthStore,
  useConfigStore,
  useImpersonationStore,
  useLocationStore,
  useTheme
};
